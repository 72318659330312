import axios from 'axios'

export default function () {
  const requireAllSvg = r => r.keys().forEach(r),
        path = 'wp-content/themes/lobods-2018/'
  requireAllSvg(require.context('../sprite-source/', true, /\.svg$/))

  // load sprite on page
  axios
    .get(path + 'dist/svg/icons.svg')
    .then(r => (document.getElementById('svg-icons').innerHTML = r.data))
}
