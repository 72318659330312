// Settings
import loadSvgSprites from './load-sprites'
import { setting } from './setting'
// jQuery
import $ from 'jquery'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery.marquee'
import 'jquery-countto'
// Plugins
import AOS from 'aos'
import Parallax from 'parallax-js'
import CircleType from 'circletype'
// Custom
import { init, animate } from './glitch/three-glitch'
// import './glitch/glitch'

const App = new function() {
  this.init = function() {
    // Random integer value
    function randomInt(min, max) {
      let rand = min + Math.random() * (max + 1 - min);
      rand = Math.floor(rand);
      return rand;
    }
    // SVG load
    loadSvgSprites()
    // Loader progress
    var loader = $('.loader'),
        imgCount = $('img').length,
        percent = 100 / imgCount,
        progress = 0,
        loadedImg = 0;

    if (imgCount > 0) {
      $('body').css('overflow', 'hidden')
      for (let i = 0; i < imgCount; i++) {
        let imgCopy = new Image()
        imgCopy.src = document.images[i].src
        imgCopy.onload = imgLoad
        imgCopy.onerror = imgLoad
      }
      function imgLoad() {
        progress += percent
        loadedImg ++
        $('.loader-jdun .resize').animate({height: 100 - progress + '%'}, randomInt(5, 50), function() {
          if ((progress >= 100 || loadedImg == imgCount) && $(this).height() == 0) {
            setTimeout(function() {
              $('body').css('overflow', '')
              loader.addClass('loaded')
              // AOS init
              AOS.init(setting.aos)
            }, 600)
          }
        })
      }
    }
    else loader.addClass('loaded')

    // Parallax
    const mark = document.getElementById('mark-zuc')
    let parallaxInstance = new Parallax(mark, {
      // relativeInput: true
    });

    // CountTo
    let isDone = true,
    count = function() {
      $('.timer').each(function() {
        let self = $(this)
        self.countTo({
          speed: 2500,
          refreshInterval: 5,
          onComplete: function () {
            setInterval(function () {
              if (self.attr('data-delay') == 0) return
              let num = parseInt(self.text())
              self.text(num + 1)
            }, self.attr('data-delay'));
          }
        });
      })
    }
    $(window).on("scroll touchmove", function() {
      if ($(document).scrollTop() < $('.numbers').position().top && isDone) {
        count()
        isDone = false
      }
    })
    // Mobile nav
    $('.page-header__button').click(function() {
      $('.page-header__nav').css('width', '100%');
    })
    $('.top-bar svg').click(function() {
      $('.page-header__nav').css('width', '0');
    })
    // Marquee
    window.creepingLine = $('.creeping-line__text').marquee()
    $('.contacts .h1').marquee({direction: 'right'})
    // Draggable block
    $('.services .page-section__inner').draggable(setting.draggable)
    // Change colors
    $(window).on("scroll touchmove", function() {
      if ($(document).scrollTop() >= $('.top-hero').position().top - 150)
        $('body').css('background', $('.top-hero').attr('data-color'))
      if ($(document).scrollTop() > $('.numbers').position().top - 150)
        $('body').css('background', $('.numbers').attr('data-color'));
      if ($(document).scrollTop() > $('.partner').position().top - 100)
        $('body').css('background', $('.partner').attr('data-color'))
      if ($(document).scrollTop() > $('.services').position().top - 150)
        $('body').css('background', $('.services').attr('data-color'))
      if ($(document).scrollTop() > $('.clients').position().top - 150)
        $('body').css('background', $('.clients').attr('data-color'))
      if ($(document).scrollTop() > $('.contacts').position().top - 500)
        $('body').css('background', $('.contacts').attr('data-color'))
    });
    // Slide-toggler
    const currenClients = $('.current-clients').slice(6),
          oldClients =  $('.old-clients').slice(6)
    currenClients.wrapAll('<div class="row m-0 collapse" style="width: 100%; display:none;"></div>')
    oldClients.wrapAll('<div class="row m-0 collapse" style="width: 100%; display:none;"></div>')
    $('.clients .btn').click(function() {
      $(this).prev('.collapse').slideToggle(300)
    })
    // CircleType
    const circleType = document.querySelectorAll('.gifs__text')
    circleType.forEach(el => new CircleType(el))
    // Typewriter
    const typeWriter = selector => {
      const el = document.querySelector(selector)
      // if reload - remove cursor
      const oldNodes = document.querySelectorAll(selector + ' span')
      if (oldNodes.length != 0) oldNodes.forEach(e => e.parentNode.removeChild(e))
      const text = el.innerHTML
      ;(function _type(i = 0) {
        if (i === text.length) return
        el.innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>'
        setTimeout(() => _type(i + 1), 30)
      })()
    }
    setInterval(function () {
      typeWriter('.typewriter__text')
    }, 10000);
    // To top + anchor
    $('.page-header__list a').click(function(e) {
        e.preventDefault()
        $('.page-header__nav').css('width', '0');
        let el =	$(this).attr('href')
        $('html, body').animate({
            scrollTop: ($(el).offset().top)
        }, 500);
    });
    $('.to-top').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 600);
        return false;
    });
    // Glitch
    init()
    animate()
    console.log('App Init')
  }
}()

App.init()
