import { WebGLRenderer, Scene, PerspectiveCamera } from 'three'
import EffectComposer, { ShaderPass, CopyShader } from 'three-effectcomposer-es6'
import { DigitalGlitch, GlitchPass } from 'three-addons'

// Glitch
var camera, scene, renderer, composer;

var glitchPass;

window.onmousedown = function(){
   glitchPass.goWild = true;
}
window.onmouseup = function(){
   glitchPass.goWild = false;
}

export function init() {
  renderer = new WebGLRenderer({ alpha: true });
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  document.body.appendChild( renderer.domElement );

  camera = new PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 1, 1000 );
  camera.position.z = 400;

  scene = new Scene();

  // postprocessing

  composer = new EffectComposer( renderer );

  glitchPass = new GlitchPass();
  glitchPass.generateTrigger = function() {
    this.randX = 0
  }

  glitchPass.renderToScreen = true;
  composer.addPass(glitchPass);
  window.addEventListener( 'resize', onWindowResize, false );
}
function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
  composer.setSize( window.innerWidth, window.innerHeight );

}

export function animate() {
  requestAnimationFrame( animate );
  composer.render();
}
