export const setting = {
  aos: {
    disable: 'mobile',
    offset: 200,
    duration: 600
  },
  draggable: {
    handle : 'h6',
    containment : '.services'
  }
}
